










































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {RestClientApergia} from "@/utilities/RestClientApergia";
import {Component, Mixins, Watch} from "vue-property-decorator";

@Component({})
export default class PageAdminContent extends Mixins(ComponentBasic) {
    LN: string = "[PageAdminEditor]::";
    dynamicComponent: any = null;
    fieldContent: string = 'not-set';
    fieldSideBar: boolean = false;
    fieldType: string = '';
    libTypes: string[] = ['Not loaded - Refresh'];
    fieldEditorShowAce: boolean = true;
    fieldEditorShowTab: boolean = true;
    hintData: string = '';
    hintContent: string = '';

    created() {
        this.setUpContentTypes();
    }

    onSave() {
        let formData = new FormData();
        formData.set('token', this.getToken() || '');
        formData.set('type', this.fieldType);
        formData.set('content', this.fieldContent);
        formData.set('properties', JSON.stringify({'show_common_bar': this.fieldSideBar}));

        RestClientApergia.adminSaveContent(
            val => {
                this.EventThrow('notify', "Saved");
                this.LoadSettings();
            },
            val => {
                this.EventThrow('notify', "Failed !!!");
            },
            formData);
    }

    createPreview() {
        try {
            this.dynamicComponent = this.CreateComponent(this.fieldContent/*, this.fieldData, this.fieldStyle*/);
        } catch (err) {
            this.dynamicComponent = this.CreateComponent("Error, reason:" + err);
        }
    }

    setUpContentTypes(defaultValue = 'loading..') {
        this.libTypes = [];
        if (this.contentMap)
            this.contentMap.forEach((value: any, key: string) => this.libTypes.push(key));
        else
            this.libTypes = [defaultValue];
    }

    /*On Change content/settings from server*/
    @Watch('contentMap')
    onChangeContentMap(val: any) {
        this.setUpContentTypes('error/refresh');
    }

    /*On Change user selection (type)*/
    @Watch('fieldType')
    onChangeFieldType(val: any) {
        if (this.contentMap != null) {
            let structure = this.contentMap.get(val) || {content: 'n/a',};
            this.fieldContent = structure.content;
            this.fieldSideBar = structure.properties != null && structure.properties.show_common_bar == 'true';
        } else {
            this.fieldContent = 'n/a';
        }
    }

    /*On Change user selection (content)*/
    @Watch('fieldContent')
    onChangeFieldContent(val: any) {
        this.createPreview();
    }
}
